/* These styles are used if a demo specific stylesheet is not present */

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

:root {
  --Grey-5: #3B3B3A;
  --Grey-3: #868685;
  --white: #FCFCFC;
  --color-theme-05: #007A33;
  --Error: #D11E1E;
}

@font-face {
  font-family: "Avenir-Book";
  src: url("../../public/assets/fonts/AvenirBook.ttf");
}

@font-face {
  font-family: "Avenir-Heavy";
  src: url("../../public/assets/fonts/AvenirHeavy.ttf");
}

@font-face {
  font-family: "Avenir-Black";
  src: url("../../public/assets/fonts/AvenirBlack.ttf");
}

body {
    font-family: 'Avenir-Book', sans-serif;
    padding: 20px;
    margin: 0;
}
iframe {
  padding: 0;
  border: 0;
  outline: 0;
  position: relative;
}
.title{
  font-family: 'Avenir-Black', sans-serif;
  color: var(--Grey-5);
  text-align: center;
  font-size: 22px;
  line-height: 26px; 
  text-transform: capitalize;
  margin-bottom: 15px;
}
.text{
  font-family: 'Avenir-Book', sans-serif;
  color: var(--Grey-5);
  text-align: center;
  font-size: 15px;
  line-height:  22.5px;
}

.responsive-img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.button {
  font-family: 'Avenir-Heavy', sans-serif;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  font-size: 14px;
  box-sizing: border-box;
  text-transform: uppercase;
  margin-bottom: 20px;
  vertical-align: middle;
  align-content: center;
}
.button.primary {
  background-color: var(--color-theme-05);
  color: var(--white);
}
.button.primary:disabled{
  background-color: var(--Grey-3);
}
.btn-container{
  width: 100%;
}
.error{
  color: var(--Error);
  font-family: Avenir-Book, sans-serif;
  font-size: 12px;
  line-height:  18px;
}